import style from './style.module.css';
import MembershipTitle from "../../MembershipTitle";
import AreaText from "../../AreaText";
import RedButton from "../../RedButton";
import { Formik } from 'formik';
import { useUser } from '../../../context/UserContext';
import { updateUser } from '../../../utils/api';
import { useLogin } from '../../../context/LoginContext';
import { toast, ToastContainer } from 'react-toastify';

export default function Area(){
    const title = 'Member Area';
    const areaText = <div>Show your profile in the IDI <a className={`${style['memberArea_link']}`} href="/member-area/" target="_blank" rel="noopener noreferrer">Member Area</a> if you want to share your personal information and contacts with the other members.</div>;
    const textButton = 'Save';

    const {user} = useUser()
    const {checkLogin} = useLogin()

    const doSave = async values => {
        const res = await updateUser(user.id, values)
        if (res === true){
            toast.success("Profile Updated!")
            return checkLogin()
             
        }else{
            toast.error("Something went wrong")
        }
    }

    return(
        <div className={`${style['membership__container']}`}>
           <MembershipTitle title={title}/>
           <AreaText areaText={areaText}/>
           <Formik
            initialValues={{ member_area: user.member_area, privacy_policy: user.privacy_policy }}
            onSubmit = {(values) => { 
                doSave(values)
            }}
            >
            {({
                values,
                errors,
                handleSubmit,
                setFieldValue
            }) => ( 
            <>
                <div className={`${style['switch__container']}`}>
                    <label className={`${style['switch']}`}>
                    <input type="checkbox" name="member_area" checked={values.member_area} onChange={(event) => setFieldValue('member_area', event.target.checked)}/>
                    <span className={`${style['slider']} ${style['round']}`}></span>
                    </label> 
                    <div className={`${style['switch-privacy__container']}`}>
                        <input type="checkbox" name="privacy_policy" errors={errors.privacy_policy} checked={values.privacy_policy} onChange={(event) => setFieldValue('privacy_policy', event.target.checked)}/>
                        <p className={`${style['switch-privacy__text']}`}>I accept the <a className={`${style['switch-privacy__link']}`} href="/idi-privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                    </div>
                </div>  
            
                <RedButton textButton={textButton} onClick={handleSubmit}/>
           </>
           )}
           </Formik>
           <ToastContainer
            position="bottom-right"
            autoClose={3000}
            />
        </div>
    )
}